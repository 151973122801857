import Vue, {CreateElement, VNode} from 'vue';
import App from './App.vue';
import VueRouter, {Route, NavigationGuardNext, RouteRecord} from 'vue-router';
import OpenShiftOverview from '@/components/views/OpenShiftOverview.vue';
import OpenShiftHistory from '@/components/views/OpenShiftHistory.vue';
import OpenShiftView from '@/components/views/OpenShiftView.vue';
import Login from '@/components/views/Login.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChangePassword from '@/components/views/ChangePassword.vue';
import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';
import Callback from '@/components/views/Callback.vue';
import Redirect from '@/components/views/Redirect.vue';
import '@/style/cq-ui-imports.scss';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router: VueRouter = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/login',
            component: Login,
            name: 'login',
            props: true,
        },
        {
            path: '/overview',
            component: OpenShiftOverview,
            name: 'open-shift-overview',
            props: true,
            meta: {
                authRequired: true
            }
        },
        {
            path: '/overview/:id',
            component: OpenShiftView,
            name: 'open-shift',
            props: true,
            meta: {
                authRequired: true
            }
        },
        {
            path: '/history',
            component: OpenShiftHistory,
            name: 'open-shift-history',
            props: true,
            meta: {
                authRequired: true
            }
        },
        {
            path: '/change-password',
            component: ChangePassword,
            name: 'change-password',
            props: true,
            meta: {
                authRequired: true
            }
        },
        {
            path: '/auth/callback',
            component: Callback,
            name: 'auth-callback',
            props: true
        },
        {
            path: '/redirect',
            component: Redirect,
            name: 'redirect',
            props: true
        },
        {
            path: '*',
            beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
                if (to.matched.some((record: RouteRecord) => record.meta.authRequired)) {
                    if (!MarketplaceAPIClient.getAccessToken()) {
                        next({name: 'login'});
                    } else {
                        next({name: 'open-shift-overview'});
                    }
                } else {
                    next({name: 'login'});
                }
            }
        }
    ]
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    document.title = 'Roosterplanning - Marktplaats';
    if (to.name !== 'redirect' && to.name !== 'auth-callback' && to.name !== 'login' && to.path !== '/') {
        localStorage.setItem('lastVisited', String(to.fullPath));
    }
    if (to.matched.some((record: RouteRecord) => record.meta.authRequired)) {
        if (!MarketplaceAPIClient.getAccessToken()) {
            next({name: 'login'});
        } else {
            next();
        }
    } else {
        next();
    }
});

/**
 * Must be before the Vue init to make sure it's tracking all errors
 */
Sentry.init({
    Vue,
    dsn: ENV_CONFIG.SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [window.location.hostname, /^\//],
        }),
    ],
    tracesSampleRate: ENV_CONFIG.SENTRY_TRACES_SAMPLE_RATE,
});

new Vue({
    router,
    render: (h: CreateElement): VNode => h(App),
}).$mount('#app');