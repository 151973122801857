
    import {Vue, Component} from 'vue-property-decorator';
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';


    @Component({})
    export default class App extends Vue {
        private publicPages: Array<string> = ['/login'];
        private username?: string | null = null;

        updated(): void {
            this.username = MarketplaceAPIClient.getUsername();
        }

        setUsername(username: string): void {
            MarketplaceAPIClient.setUsername(username);
        }
    }

