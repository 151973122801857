
    import {Vue, Component} from 'vue-property-decorator';
    import {Employee, OpenShift, OpenShiftApplication} from '@/assets/MarktplaatsInterfaces';
    import {CqButton, CqTextInput, CqWidget, CqNotifications} from '@cquential/ui';
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';
    import {AxiosError} from 'axios';
    import {ApplicationState} from '@/types/enums/ApplicationState';
    import {ShiftState} from '@/types/enums/ShiftState';
    import {UserNotification} from '@cquential/ui/types/notifications';

    @Component({
        components: {
            CqButton,
            CqTextInput,
            CqWidget,
            CqNotifications,
        }
    })
    export default class OpenShiftView extends Vue {
        private readonly RETRIEVE_EMPLOYEE_DATA_TIMEOUT: number = 500;
        private readonly HIDE_MODAL_ERROR_TIMEOUT: number = 5000;

        private openShift: OpenShift | undefined | null = null;
        private openShiftId: number | null = null;

        private openShiftApplications: OpenShiftApplication[] | null | undefined = null;

        private showModal: boolean | null = false;
        private employeeName: string = '';
        private employeePhone: string | null = null;
        private employeeEmail: string | null = null;
        private employeeComment: string = '';

        private isSending: boolean = false;
        private enableSend: boolean = false;
        private enableInput: boolean = false;
        private timeout?: number = undefined;
        private notifications: UserNotification[] = [];


        private get phone(): string {
            return this.employeePhone ?? '';
        }

        private set phone(value: string) {
            this.employeePhone = value;
        }

        private get email(): string {
            return this.employeeEmail ?? '';
        }

        private set email(value: string) {
            this.employeeEmail = value;
        }

        mounted(): void {
            if (this.$route.params.openShift != undefined) {
                this.openShift = JSON.parse(this.$route.params.openShift) as unknown as OpenShift;
                this.openShift.timeBegin = new Date(this.openShift.timeBegin);
                this.openShift.timeEnd = new Date(this.openShift.timeEnd);
            }
            this.openShiftId = parseInt(this.$route.params.id);

            if (!this.openShift) {
                this.fetchOpenShift(this.openShiftId);
            }

            MarketplaceAPIClient.getOpenShiftApplications(this.openShiftId).then((response: OpenShiftApplication[]) => {
                this.openShiftApplications = response;
            });
        }

        private fetchOpenShift(openShiftId: number): void {
            MarketplaceAPIClient.getSingleOpenShift(openShiftId).then((response: OpenShift): void => {
                this.openShift = response;
                if (this.openShift) {
                    this.openShift.timeBegin = new Date(response.timeBegin);
                    this.openShift.timeEnd = new Date(response.timeEnd);
                }
            }).catch((): void => {
                this.$router.push({name: 'open-shift-overview'});
            });
        }

        private isRegistered(application: OpenShiftApplication): boolean {
            return application.state.id === ApplicationState.REGISTERED;
        }

        private applicationState(application: OpenShiftApplication): string {
            switch (application.state.id) {
                case ApplicationState.CHOSEN:
                    return 'application-accepted';
                case ApplicationState.WITHDRAWN:
                case ApplicationState.REJECTED:
                    return 'application-rejected';
                default:
                    return '';
            }
        }

        private employeeHasActiveApplication(employeeName: string): boolean {
            if (this.openShiftApplications) {
                const application: OpenShiftApplication | undefined = this.openShiftApplications.find((application: OpenShiftApplication): boolean => {
                    return application.employee.name === employeeName
                        && application.state.name === 'aangemeld';
                });
                if (application) {
                    this.notifications.push({type: 'error', text: `Medewerker met naam '${employeeName}' is al aangemeld.`});
                    return true;
                }
            }
            return false;
        }

        private handleNewOpenShiftApplication(): void {
            if (!this.enableSend || this.isSending || this.employeeHasActiveApplication(this.employeeName)) {
                return;
            }

            this.isSending = true;

            MarketplaceAPIClient.getEmployeeIdBySubmittingEmployee(this.employeeName, this.employeePhone, this.employeeEmail).then((employeeId: number): Promise<void> => {
                return MarketplaceAPIClient.postOpenShiftApplication(this.openShiftId!, employeeId, this.employeeComment).then((): void => {
                    MarketplaceAPIClient.getOpenShiftApplications(this.openShiftId!).then((response: OpenShiftApplication[]): void => {
                        this.openShiftApplications = response;
                        this.employeeName = '';
                        this.employeePhone = null;
                        this.employeeEmail = null;
                        this.employeeComment = '';
                        this.enableSend = false;
                        this.enableInput = true;
                    });
                    this.closeModal();
                }).catch((error: AxiosError): void => {
                    if (error.response?.status === 409) {
                        if (this.openShiftId) {
                            this.fetchOpenShift(this.openShiftId);
                        }
                    } else if (error.response?.status === 422) {
                        if (error.response?.data?.errors) {
                            const rawErrors: Record<string, string[]> = error.response.data.errors;
                            let message: string = '';
                            for (message of Object.values(rawErrors).flat()) {
                                this.notifications.push({type: 'error', text: message});
                            }
                        }
                        this.enableSend = true;
                    } else {
                        throw error;
                    }
                });
            }).finally((): void => {
                this.isSending = false;
            });
        }

        private withdrawApplication(application: OpenShiftApplication): void {
            MarketplaceAPIClient.withdrawApplication(application.id).then((): void => {
                MarketplaceAPIClient.getOpenShiftApplications(this.openShiftId).then((response: OpenShiftApplication[]): void => {
                    this.openShiftApplications = response;
                });
            });
        }

        private getTimeRange(shift: OpenShift): string {
            const timeBegin: string = shift.timeBegin.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'Europe/Amsterdam'
            });

            const timeEnd: string = shift.timeEnd.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'Europe/Amsterdam'
            });

            return (timeBegin + ' - ' + timeEnd);
        }

        private getBeginDate(openShift: OpenShift): string {
            return openShift.timeBegin.toLocaleDateString().replaceAll('/', '-');
        }

        private canCreateApplication(openShift: OpenShift): boolean {
            return (openShift.isActive && openShift.state.id === ShiftState.OPEN && openShift.timeBegin > new Date());
        }

        private handleNameChange(): void {
            this.enableSend = false;
            this.enableInput = false;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(this.retrieveEmployeeData, this.RETRIEVE_EMPLOYEE_DATA_TIMEOUT);
        }

        private retrieveEmployeeData(): void {
            if (this.employeeName.length > 2 && !this.employeeHasActiveApplication(this.employeeName)) {
                MarketplaceAPIClient.getEmployeeByName(this.employeeName).then((employee: Employee | undefined): void => {
                    this.employeePhone = employee?.phone ?? null;
                    this.employeeEmail = employee?.email ?? null;
                    this.enableSend = true;
                    this.enableInput = true;
                });
            }
        }

        private closeModal(): void {
            this.showModal = false;
        }

        private openModal(): void {
            this.showModal = true;
        }
    }
