
    import Vue from 'vue';
    import {Component} from 'vue-property-decorator';
    import {OpenShift} from '@/assets/MarktplaatsInterfaces';
    import ShiftOverview from '@/components/ShiftOverview.vue';
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';

    @Component({
        components: {ShiftOverview}
    })
    export default class OpenShiftOverview extends Vue {
        private shifts: OpenShift[] | null = null;
        private getOpenShiftsInterval: number | undefined = undefined;

        mounted(): void {
            this.getOpenShiftsFromAPI();

            this.getOpenShiftsInterval = setInterval(() => {
                this.getOpenShiftsFromAPI();
            }, 1000 * 60 * 5);
        }

        destroyed(): void {
            clearInterval(this.getOpenShiftsInterval);
        }

        private getOpenShiftsFromAPI(): void {
            MarketplaceAPIClient.getOpenShifts(true).then((response: OpenShift[]) => {
                this.shifts = response;
            });
        }
    }
