
    import {Vue, Component} from 'vue-property-decorator';
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';

    @Component({})
    export default class Login extends Vue {
        created(): void {
            if (this.$route.params.logout) {
                localStorage.removeItem('lastVisited');
                MarketplaceAPIClient.logout();
            } else {
                MarketplaceAPIClient.marketplaceLogin();
            }
        }
    }
