
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';
    import Vue from 'vue';
    import {Component} from 'vue-property-decorator';
    import {CqButton, CqCard, CqTextInput} from '@cquential/ui';

    @Component({
        components: {
            CqButton,
            CqCard,
            CqTextInput,
        }
    })
    export default class ChangePassword extends Vue {
        private oldPassword: string = '';
        private newPassword: string = '';
        private newPasswordCheck: string = '';

        private changePassword(): void {
            if (this.newPassword === this.newPasswordCheck) {
                MarketplaceAPIClient.changePassword(this.oldPassword, this.newPassword).then(() => {
                    alert('Wachtwoord gewijzigd!');
                    this.oldPassword = '';
                    this.newPassword = '';
                    this.newPasswordCheck = '';
                    this.$router.push({name: 'open-shift-overview'});
                }).catch((error: string) => {
                    alert(error);
                });
            } else {
                alert('Nieuwe wachtwoorden komen niet overeen');
            }
        }
    }
