
    import Vue from 'vue';
    import {Component, Prop} from 'vue-property-decorator';
    import {OpenShift} from '@/assets/MarktplaatsInterfaces';

    @Component({})
    export default class ShiftOverview extends Vue {
        @Prop() shifts!: OpenShift[];
        @Prop(Boolean) sortDescending?: boolean;

        private get openShifts(): OpenShift[] {
            return this.sortDescending === true
                ? [...this.shifts].sort((a: OpenShift, b: OpenShift) => b.timeBegin.getTime() - a.timeBegin.getTime())
                : this.shifts;
        }

        private goToOpenShift(openShift: OpenShift): void {
            this.$router.push({name: 'open-shift', params: {id: String(openShift.id), openShift: JSON.stringify(openShift)}});
        }

        private getTimeRange(shift: OpenShift): string {
            const timeBegin: string = shift.timeBegin.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'Europe/Amsterdam'
            });

            const timeEnd: string = shift.timeEnd.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'Europe/Amsterdam'
            });

            return (timeBegin + ' - ' + timeEnd);
        }

        private getBeginDate(openShift: OpenShift): string {
            return openShift.timeBegin.toLocaleDateString().replaceAll('/', '-');
        }
    }
