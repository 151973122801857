
    import {Vue, Component} from 'vue-property-decorator';
    import {MarketplaceAPIClient} from '@/util/MarketplaceAPIClient';

    @Component({})
    export default class Redirect extends Vue {
        created(): void {
            MarketplaceAPIClient.marketplaceRedirect();
        }
    }
